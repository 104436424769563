import jwt_decode from 'jwt-decode';
const jwtEncode = require('jwt-encode');

export const getLocalUser = (localKey) => getDecryptedLocalData(localKey);

// Get Decrypted Local Data
export const getDecryptedLocalData = (localKey) => {
  if (typeof window !== 'undefined') {
    const localEncryptedData = localStorage.getItem(localKey);
    if (localEncryptedData) {
      return decodeLocalData(localEncryptedData);
    }
  } else {
    return null;
  }
};

// Set Encrypted Local Data
export const setEncryptedLocalData = (data, localKey, secretKey) => {
  if (typeof window !== 'undefined') {
    const encryptedData = encodeLocalData(data, secretKey);
    localStorage.setItem(localKey, encryptedData);
  }
};

// Encode Local Data
export const encodeLocalData = (data, secret) => jwtEncode(data, secret);

// Decode Local Data
export const decodeLocalData = (encryptedData) => jwt_decode(encryptedData);
