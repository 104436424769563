import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import AddClient from "./components/AddClient";
import "materialize-css/dist/css/materialize.min.css";
import "./App.css";
import { AuthProvider } from "./context/Auth";
import { ViewClient } from "./components/ViewClient";
import EditClient from "./components/EditProfile";
import HomePage from "./components/HomePage";
import AdminLoginPage from "./components/AdminLogin";
import ViewClintPage from "./components/ViewClint";
import { useAuth } from "./hooks";

function App() {
  const { user } = useAuth();

  return (
    <AuthProvider>
      <Router>
        <div className="content">
          <>
            <NavBar />
            <Routes>
              <Route exact path="/admin" element={<AdminLoginPage />} />
              {user ? (
                <>
                  <Route
                    exact
                    path="/view/client"
                    element={<ViewClintPage />}
                  />
                  <Route exact path="/Dashboard" element={<Dashboard />} />
                  <Route exact path="/HomePage" element={<HomePage />} />
                  <Route exact path="/add/client" element={<AddClient />} />
                  <Route
                    exact
                    path="/view/client/:id"
                    element={<ViewClient />}
                  />
                  <Route
                    exact
                    path="/view/client/:id/edit"
                    element={<EditClient />}
                  />
                </>
              ) : (
                <Route exact path="/" element={<Login />} />
              )}
              <Route
                path="*"
                element={<Navigate to={user ? "/Homepage" : "/"} />}
              />
            </Routes>
          </>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
