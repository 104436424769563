import React, { useState, useContext, useEffect } from "react";
import firebase from "../firebaseConfig";
import { Link } from "react-router-dom";
import M from "materialize-css/dist/js/materialize.min.js";
import spinner from "../images/loadingSpinner.gif";
import { AuthContext } from "../context/Auth";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAuth } from "../hooks";
import { addStoryItem } from "../services/addServices";
import { collectionNames } from "../constants/firebase";

const AddClient = (props) => {
  const { user } = useAuth();
  const [values, setValues] = useState({
    schoolName: "",
    district: "",
    state: "",
    email: "",
    city: "",
    pin: "",
    contact: "",
    address: "",
    type: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {
    schoolName,
    district,
    state,
    email,
    city,
    pin,
    contact,
    address,
    type,
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const newClient = {
        schoolName,
        district,
        state,
        email,
        city,
        pin,
        contact,
        address,
        type,
        user: user.email,
      };

      const res = await addStoryItem(collectionNames.CLIENTS, newClient);

      if (res) {
        M.toast({
          html: "client added succesfully",
          classes: "green darken-1 rounded",
        });
        setIsLoading(false);
        resetForm();
        navigate("/Dashboard");
      } else {
        M.toast({
          html: "Something went wrong. Please try again.",
          classes: "red darken-1 rounded",
        });
        setIsLoading(false);
      }
    } catch (error) {
      M.toast({
        html: "Something went wrong. Please try again.",
        classes: "red darken-1 rounded",
      });
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const resetForm = () => {
    setValues({
      schoolName: "",
      district: "",
      state: "",
      email: "",
      city: "",
      pin: "",
      contact: "",
      address: "",
      type: "",
    });
  };

  return isLoading ? (
    <div className="spinner">
      <img src={spinner} alt="loading-spinner" />
    </div>
  ) : (
    <div className="row">
      <div className="col s12 m8 offset-m2">
        <Link
          to="#"
          onClick={() => navigate(-1)}
          className="waves-effect waves-light btn"
        >
          <i className="material-icons left">arrow_back</i>Back to dashboard
        </Link>

        <div className="card">
          <div className="card-content">
            <h5 className="card-title center">Add New Client</h5>
            <div className="row">
              <div className="col s12 m7">
                <form encType="multipart/form-data" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="input-field col s12">
                      <FormControl fullWidth>
                        <label>Type</label>
                        <Select value={type} onChange={handleChange("type")}>
                          <MenuItem value="Prospect">Prospect</MenuItem>
                          <MenuItem value="Client">Client</MenuItem>
                          <MenuItem value="Partial Client">
                            Partial Client
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="input-field col s6">
                      <input
                        id="schoolName"
                        type="text"
                        onChange={handleChange("schoolName")}
                        value={schoolName}
                        required
                      />
                      <label htmlFor="title">School Name</label>
                    </div>
                    <div className="input-field col s6">
                      <input
                        id="email"
                        type="email"
                        onChange={handleChange("email")}
                        value={email}
                        // required
                      />
                      <label htmlFor="date_published">Email</label>
                    </div>
                    <div className="input-field col s4">
                      <input
                        id="district"
                        type="text"
                        onChange={handleChange("district")}
                        value={district}
                        required
                      />
                      <label htmlFor="genre">District</label>
                    </div>
                    <div className="input-field col s4">
                      <input
                        id="state"
                        type="text"
                        onChange={handleChange("state")}
                        value={state}
                        required
                      />
                      <label htmlFor="no_copies">State</label>
                    </div>

                    <div className="input-field col s4">
                      <input
                        id="city"
                        type="text"
                        onChange={handleChange("city")}
                        value={city}
                        required
                      />
                      <label htmlFor="no_copies">City</label>
                    </div>
                    <div className="input-field col s4">
                      <input
                        id="pin"
                        type="text"
                        onChange={handleChange("pin")}
                        value={pin}
                        required
                      />
                      <label htmlFor="no_copies">PIN</label>
                    </div>
                    <div className="input-field col s8">
                      <input
                        id="contact"
                        type="text"
                        onChange={handleChange("contact")}
                        value={contact}
                        required
                      />
                      <label htmlFor="no_copies">Contact</label>
                    </div>
                    <div className="input-field col s12">
                      <input
                        id="address"
                        type="text"
                        onChange={handleChange("address")}
                        value={address}
                        required
                      ></input>
                      <label htmlFor="description">Address</label>
                    </div>
                  </div>
                  <div className="center">
                    <button
                      className="waves-effect waves-light btn"
                      style={{ margin: "8px 18px" }}
                      type="submit"
                    >
                      ADD Client{" "}
                      <i className="material-icons right">add_circle_outline</i>
                    </button>
                    <button
                      className="waves-effect waves-light btn red"
                      type="reset"
                      onClick={resetForm}
                    >
                      Reset <i className="material-icons right">cancel</i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClient;
