import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";

import { getLocalUser, setEncryptedLocalData } from "../utils/localStorage";
import { localKeys, secretKeys } from "../constants/localStorage";
import { auth } from "../firebaseConfig";

const useAuth = () => {
  const [user, setUser] = useState(getLocalUser(localKeys.AUTH));

  useEffect(() => {
    const listener = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        const isAdmin = ["admin@gmail.com", "info@yep.education"].includes(
          authUser.email
        );
        setUser({ ...authUser, isAdmin: isAdmin });
        setEncryptedLocalData(
          { ...authUser, isAdmin: isAdmin },
          localKeys?.AUTH,
          secretKeys?.AUTH
        );
        setEncryptedLocalData(
          isAdmin,
          localKeys?.IS_ADMIN,
          secretKeys?.IS_ADMIN
        );
      } else {
        localStorage.clear();
        setUser(null);
      }
    });

    return () => listener();
    //eslint-disable-next-line
  }, []);

  return { user };
};

export default useAuth;
