import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import M from "materialize-css/dist/js/materialize.min.js";
import spinner from "../images/loadingSpinner.gif";
import { useParams } from "react-router";
import { AuthContext } from "../context/Auth";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useAuth, useFirebaseData } from "../hooks";
import { collectionNames } from "../constants/firebase";
import { firebaseQueryOperators } from "../utils/queryBuilder";
import { updateStoryItem } from "../services/updateServices";

const EditClient = () => {
  const { id } = useParams();
  const [values, setValues] = useState({
    schoolName: "",
    district: "",
    state: "",
    email: "",
    city: "",
    pin: "",
    contact: "",
    address: "",
    type: "",
  });

  const { data, isFetching: isLoading } = useFirebaseData(
    collectionNames.CLIENTS,
    [
      {
        property: "id",
        operator: firebaseQueryOperators.EQUAL_TO,
        value: id,
      },
    ]
  );

  useEffect(() => {
    if (data && data.length > 0) {
      setValues({ ...data.at(0) });
    }
  }, [data]);

  const {
    schoolName,
    district,
    state,
    email,
    city,
    pin,
    contact,
    address,
    type,
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updateClient = {
        schoolName,
        district,
        state,
        email,
        city,
        pin,
        contact,
        address,
        type,
      };
      const res = await updateStoryItem(collectionNames.CLIENTS, {
        ...updateClient,
        id,
      });
      if (res) {
        M.toast({
          html: "Client updated succesfully",
          classes: "green darken-1 rounded",
        });
      } else {
        M.toast({
          html: "Something went wrong. Please try again.",
          classes: "red darken-1 rounded",
        });
      }
    } catch (error) {
      M.toast({
        html: "Something went wrong. Please try again.",
        classes: "red darken-1 rounded",
      });
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  return isLoading ? (
    <div className="spinner">
      <img src={spinner} alt="loading-spinner" />
    </div>
  ) : (
    <div className="row">
      <br />
      <div className="col s12 m8 offset-m2">
        <Link
          to={`/view/client/${id}`}
          className="waves-effect waves-light btn"
        >
          <i className="material-icons left">arrow_back</i>Back to Profile
        </Link>

        <div className="card">
          <div className="card-content">
            <h5 className="center">Update Client</h5>
            <div className="row">
              <div className="col s12 m7">
                <form encType="multipart/form-data" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="input-field col s12">
                      <FormControl fullWidth>
                        <label>Type</label>
                        <Select value={type} onChange={handleChange("type")}>
                          <MenuItem value="Prospect">Prospect</MenuItem>
                          <MenuItem value="Client">Client</MenuItem>
                          <MenuItem value="Partial Client">
                            Partial Client
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="input-field col s6">
                      <input
                        placeholder="School Name"
                        type="text"
                        onChange={handleChange("schoolName")}
                        value={schoolName}
                        required
                      />
                    </div>
                    <div className="input-field col s6">
                      <input
                        placeholder="email"
                        type="email"
                        onChange={handleChange("email")}
                        value={email}
                        required
                      />
                    </div>
                    <div className="input-field col s4">
                      <input
                        placeholder="district"
                        type="text"
                        onChange={handleChange("district")}
                        value={district}
                        required
                      />
                    </div>
                    <div className="input-field col s4">
                      <input
                        placeholder="state"
                        type="text"
                        onChange={handleChange("state")}
                        value={state}
                        required
                      />
                    </div>
                    <div className="input-field col s4">
                      <input
                        placeholder="city"
                        type="text"
                        onChange={handleChange("city")}
                        value={city}
                        required
                      />
                    </div>
                    <div className="input-field col s4">
                      <input
                        placeholder="pin"
                        type="text"
                        onChange={handleChange("pin")}
                        value={pin}
                        required
                      />
                    </div>
                    <div className="input-field col s8">
                      <input
                        placeholder="contact"
                        type="text"
                        onChange={handleChange("contact")}
                        value={contact}
                        required
                      />
                    </div>
                    <div className="input-field col s12">
                      <input
                        placeholder="address"
                        type="text"
                        onChange={handleChange("address")}
                        value={address}
                        required
                      ></input>
                    </div>
                  </div>
                  <div className="center">
                    <button
                      className="waves-effect waves-light btn"
                      style={{ margin: "8px 18px" }}
                      type="submit"
                    >
                      Update Client{" "}
                      <i className="material-icons right">add_circle_outline</i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClient;
