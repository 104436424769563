import React, { useState, useEffect, useContext, useMemo } from "react";
import { db } from "../firebaseConfig";
import { Link } from "react-router-dom";
// import spinner from "../images/loadingSpinner.gif";
import { AuthContext } from "../context/Auth";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  where,
  query,
} from "firebase/firestore";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import M from "materialize-css/dist/js/materialize.min.js";
import { IoPersonCircleSharp } from "react-icons/io5";
import "./Dashboard.css";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EventsModal from "./common/eventsModal";
import { downloadJSONtoExcel, isSameDayAndMonth } from "../utils";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import { IconButton } from "@mui/material";
import { useAuth, useFirebaseData } from "../hooks";
import { collectionNames } from "../constants/firebase";
import Loader from "./common/Loader";
import { deleteStoryItem } from "../services/deleteServices";
import { getStoryItem } from "../services/getServices";
import { updateStoryItem } from "../services/updateServices";
import { firebaseQueryOperators } from "../utils/queryBuilder";
import moment from "moment";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Dashboard = (props) => {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const [filterConfig, setFilterConfig] = useState({
    city: "",
    schoolName: "",
  });

  const { data, isFetching } = useFirebaseData(collectionNames.CLIENTS, [
    {
      property: "user",
      operator: firebaseQueryOperators.EQUAL_TO,
      value: user?.email,
    },
  ]);

  const { data: eventsData, isFetching: isEventsFetching } = useFirebaseData(
    collectionNames.STAFF,
    [
      {
        property: "user",
        operator: firebaseQueryOperators.EQUAL_TO,
        value: user?.email,
      },
    ]
  );

  const events =
    eventsData && eventsData.length > 0
      ? eventsData.filter(
          ({ dob, anniversary }) =>
            (dob &&
              isSameDayAndMonth(moment(dob?.toDate()), moment(new Date()))) ||
            (anniversary &&
              isSameDayAndMonth(
                moment(anniversary?.toDate()),
                moment(new Date())
              ))
        )
      : [];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isAdmin = user?.isAdmin;
  // Toggle for download access two possible values : {isAdmin , true}
  const isUserDownloadVisible = isAdmin;

  const onDeleteClient = async (id, onClose) => {
    try {
      const res = await deleteStoryItem(collectionNames.CLIENTS, id);
      if (res) {
        onClose();
        M.toast({
          html: "client deleted succesfully",
          classes: "red darken-1 rounded",
        });
      }
    } catch (error) {
      M.toast({
        html: "Something went wrong. Please try again.",
        classes: "red darken-1 rounded",
      });
    }
  };

  const handleDelete = async (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h5>Are you sure?</h5>
            <p>You want to delete this?</p>
            <br />
            <button
              class="waves-effect waves-light btn-small"
              onClick={() => onDeleteClient(id, onClose)}
            >
              Yes
            </button>
            <button
              class="waves-effect waves-light btn-small"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
    });
  };

  const onFilter = (e) => {
    const { value, name } = e.target;
    setFilterConfig((prev) => ({ ...prev, [name]: value }));
  };

  const filteredClients = useMemo(() => {
    let filteredClients =
      data && data.length > 0 && filterConfig.city
        ? data.filter(
            (m) =>
              !!m.city.toLowerCase()?.match(filterConfig.city?.toLowerCase())
          )
        : data;

    filteredClients = filterConfig.schoolName
      ? filteredClients.filter(
          (m) =>
            !!m.schoolName
              .toLowerCase()
              ?.match(filterConfig.schoolName?.toLowerCase())
        )
      : filteredClients;

    return filteredClients;
  }, [data, filterConfig]);

  const onDownloadData = () => {
    downloadJSONtoExcel(
      filteredClients.map(({ schoolName, email, city }, index) => ({
        no: index + 1,
        schoolName,
        email,
        city,
      })),
      "User Data"
    );
  };

  return (
    <div className="row">
      {isFetching ? (
        <Loader />
      ) : (
        <div className="col s12 m12">
          <br />
          <Grid
            container
            spacing={4}
            sx={{ borderWidth: 2 }}
            alignItems="center"
          >
            <Grid item md={4} sm={6} xs={6} lg={4} xl={4}>
              <div className="input-field">
                <input
                  id="name"
                  type="text"
                  className="validate"
                  style={{ maxWidth: 300 }}
                  onChange={onFilter}
                  name="city"
                />
                <label htmlFor="name">Search by City</label>
              </div>
            </Grid>
            <Grid item md={4} sm={6} xs={6} lg={4} xl={4}>
              <div className="input-field">
                <input
                  id="school"
                  type="text"
                  name="schoolName"
                  className="validate"
                  style={{ maxWidth: 300 }}
                  onChange={onFilter}
                />
                <label htmlFor="school">Search by school</label>
              </div>
            </Grid>

            <Grid item md={4} sm={6} xs={6} lg={4} xl={4}>
              <div>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleOpen}
                >
                  Activity
                </Button>
              </div>
              {open && (
                <EventsModal
                  open={open}
                  handleClose={handleClose}
                  events={events}
                  isLoading={isEventsFetching}
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDiraction: "row",
                  justifyContent: "flex-end",
                }}
              >
                {isUserDownloadVisible ? (
                  <IconButton
                    color="primary"
                    aria-label="add to shopping cart"
                    onClick={onDownloadData}
                    disabled={!filteredClients || !filteredClients.length}
                    style={{ margin: "10px", alignSelf: "flex-end" }}
                  >
                    <FileDownloadRoundedIcon />
                  </IconButton>
                ) : (
                  ""
                )}
                <Link
                  to="/add/client"
                  className="waves-effect waves-light btn green darken-3 hoverable"
                  style={{ margin: "10px 0px", alignSelf: "flex-end" }}
                >
                  ADD SCHOOL <i className="material-icons right">add</i>
                </Link>
                {isAdmin && (
                  <Link
                    to="/view/client"
                    className="waves-effect waves-light btn blue darken-3 hoverable"
                    style={{ margin: "10px 10px", alignSelf: "flex-end" }}
                  >
                    View User
                  </Link>
                )}
              </div>
            </Grid>
          </Grid>
          <div className="row">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>No . </StyledTableCell>
                    <StyledTableCell align="left">School Name</StyledTableCell>
                    <StyledTableCell align="left">Email</StyledTableCell>
                    <StyledTableCell align="left">City</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredClients && filteredClients.length > 0 ? (
                    filteredClients.map(
                      ({ city, schoolName, email, id }, index) => (
                        <StyledTableRow key={id}>
                          <StyledTableCell component="th" scope="row">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {schoolName}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {email}
                          </StyledTableCell>
                          <StyledTableCell align="left">{city}</StyledTableCell>
                          <StyledTableCell align="right">
                            <div
                              style={{
                                display: "flex",
                                flexDiraction: "row",
                                alignItems: "center",
                              }}
                            >
                              <Link
                                to={`/view/client/${id}`}
                                className="waves-effect waves-light btn darken-3 hoverable"
                              >
                                View Profile
                              </Link>
                              {isAdmin && (
                                <Link
                                  to="#"
                                  onClick={(e) => handleDelete(e, id)}
                                  className="waves-effect waves-light btn darken-3 hoverable"
                                  style={{
                                    backgroundColor: "red",
                                    marginLeft: "50px",
                                  }}
                                >
                                  Delete
                                </Link>
                              )}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    )
                  ) : (
                    <StyledTableRow>No Clients Found</StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
