import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import firebase from "../firebaseConfig";
import M from "materialize-css/dist/js/materialize.min.js";

import Background from "./loginPage.svg";
import LogoImage from "./YEP new Logo.png";

export default function AdminLoginPage() {
  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Hooks
  let navigate = useNavigate();

  // Handlers
  const login = async () => {
    try {
      const user = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      if (user && ["admin@gmail.com", "info@yep.education"].includes(email)) {
        M.toast({
          html: `Login Successfully`,
          classes: "green darken-1 rounded",
        });
        navigate("/HomePage");
      } else {
        M.toast({ html: `Invalid Credentials`, classes: "red rounded" });
      }
    } catch (error) {
      M.toast({ html: `Invalid Credentials`, classes: "red rounded" });
    }
  };

  // Return
  return (
    <div style={{ clear: "both", height: "88vh", overflow: "scroll" }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100%", overflow: "scroll" }}
      >
        <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
          <img src={LogoImage} style={{ width: "100%" }} alt="" />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <div
            className="card hoverable"
            style={{
              backgroundImage: `url(${Background})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "120% 50%",
              backgroundSize: "contain",
            }}
          >
            <div className="card-content">
              <h5 className="center">Login to Admin</h5>
              <form>
                <div className="row">
                  <div className="input-field col s12 m8 offset-m2">
                    <i className="material-icons prefix">email</i>
                    <input
                      id="email"
                      type="email"
                      className="validate"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                  <div className="input-field col s12 m8 offset-m2">
                    <i className="material-icons prefix">keyboard_hide</i>
                    <input
                      id="password"
                      type="password"
                      className="validate"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="password">Password</label>
                  </div>
                </div>
                <div className="card-action center">
                  <button
                    className="waves-effect waves-light btn"
                    style={{ margin: "18px" }}
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      login();
                    }}
                  >
                    Login
                    <i className="material-icons right">add_circle_outline</i>
                  </button>
                  <button
                    className="waves-effect waves-light btn red"
                    type="reset"
                  >
                    Reset <i className="material-icons right">cancel</i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
