// Secret keys for encryption
export const secretKeys = {
  AUTH: "authUser",
  IS_ADMIN: "isAdmin",
};

// Local storage keys
export const localKeys = {
  AUTH: "authUser",
  IS_ADMIN: "isAdmin",
};
