import {
  Modal,
  TableContainer,
  Typography,
  Paper,
  TableRow,
  TableHead,
  Table,
  TableBody,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { differenceInDays, format } from "date-fns";
import { StyledTableCell, StyledTableRow } from "../Dashboard";
import Loader from "./Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
};

const EventsModal = ({
  open,
  handleClose,
  events = [],
  eventForToday = false,
  isLoading = false,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Events
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>No . </StyledTableCell>
                    <StyledTableCell align="left">Name</StyledTableCell>
                    <StyledTableCell align="left">Email</StyledTableCell>
                    <StyledTableCell align="left">Address</StyledTableCell>
                    <StyledTableCell align="left">DOB</StyledTableCell>
                    <StyledTableCell align="left">Anniversary</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events && events.length > 0 ? (
                    events.map((row, index) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.address}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {eventForToday ? (
                            row?.dob &&
                            differenceInDays(row.dob?.toDate(), new Date()) ===
                              0 && (
                              <Typography variant="body2" color="text.primary">
                                {row?.dob &&
                                  format(row.dob.toDate(), "dd LLL yyyy")}
                                {row?.dob &&
                                row.dob.toDate().getMonth() ===
                                  new Date().getMonth()
                                  ? "- Birthday"
                                  : ""}
                              </Typography>
                            )
                          ) : (
                            <Typography variant="body2" color="text.primary">
                              {row?.dob &&
                                format(row.dob.toDate(), "dd LLL yyyy")}
                              {row?.dob &&
                              row.dob.toDate().getMonth() ===
                                new Date().getMonth()
                                ? "- Birthday"
                                : ""}
                            </Typography>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {eventForToday ? (
                            row?.anniversary &&
                            differenceInDays(
                              row?.anniversary?.toDate(),
                              new Date()
                            ) === 0 && (
                              <Typography variant="body2" color="text.primary">
                                {row?.anniversary &&
                                  format(
                                    row?.anniversary.toDate(),
                                    "dd LLL yyyy"
                                  )}
                                {row?.anniversary &&
                                row?.anniversary.toDate().getMonth() ===
                                  new Date().getMonth()
                                  ? "- Anniversary"
                                  : ""}
                              </Typography>
                            )
                          ) : (
                            <Typography variant="body2" color="text.primary">
                              {row?.anniversary &&
                                format(
                                  row?.anniversary.toDate(),
                                  "dd LLL yyyy"
                                )}
                              {row?.anniversary &&
                              row?.anniversary.toDate().getMonth() ===
                                new Date().getMonth()
                                ? "- Anniversary"
                                : ""}
                            </Typography>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <p>No Events Found</p>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default EventsModal;
