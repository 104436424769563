import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import "./ViewClient.css";
import { ProfileAccordion, StaffAccordion } from "./common/accordion";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import M from "materialize-css/dist/js/materialize.min.js";
import { useTheme } from "@mui/material/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useFirebaseData from "../hooks/use-firebase-data";
import { collectionNames } from "../constants/firebase";
import { firebaseQueryOperators } from "../utils/queryBuilder";
import { addStoryItem } from "../services/addServices";
import { useAuth } from "../hooks";
import { downloadJSONtoExcel } from "../utils";
import { FileDownloadRounded } from "@mui/icons-material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

export const ViewClient = () => {
  const { id } = useParams();

  const { data: clientData } = useFirebaseData(collectionNames.CLIENTS, [
    {
      property: "id",
      operator: firebaseQueryOperators.EQUAL_TO,
      value: id,
    },
  ]);

  const { data } = useFirebaseData(collectionNames.STAFF, [
    {
      property: "schoolId",
      operator: firebaseQueryOperators.EQUAL_TO,
      value: id,
    },
  ]);

  // change date formate to dd/mm/yyyy:
  // function formatDate(input) {
  //   let dArr = input.split("-");  // ex input "2010-01-18"
  //   let d = dArr[2]+ "/" +dArr[1]+ "/" +dArr[0].substring(2);
  //   return d;
  // }
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useAuth();
  const navigate = useNavigate();

  const isAdmin = user?.isAdmin;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSaveStaff = async (staffData) => {
    try {
      const res = await addStoryItem(collectionNames.STAFF, {
        ...staffData,
        schoolId: id,
      });
      if (res) {
        M.toast({
          html: "Contact added succesfully",
          classes: "green darken-1 rounded",
          displayLength: 2000,
        });
        handleClose();
      } else {
      }
    } catch (error) {}
  };

  const onDownloadData = () => {
    downloadJSONtoExcel(
      data.map(
        (
          {
            name,
            user,
            email,
            city,
            address,
            phone,
            state,
            maritalStatus,
            createdAt,
            anniversary,
            dob,
            designation,
            id,
            pin,
          },
          index
        ) => ({
          no: index + 1,
          id,
          name,
          user,
          email,
          designation,
          phone,
          address,
          city,
          pin,
          state,
          maritalStatus,
          anniversary: anniversary
            ? format(anniversary?.toDate(), "dd/MM/yyyy")
            : "",
          dob: dob ? format(dob?.toDate(), "dd/MM/yyyy") : "",
          createdAt: createdAt ? format(createdAt?.toDate(), "dd/MM/yyyy") : "",
        })
      ),
      `${clientData.at(0)?.schoolName || "School"} Staffs`
    );
  };

  return (
    <>
      <br />
      <Link
        to="#"
        onClick={() => navigate(-1)}
        className="waves-effect waves-light btn"
      >
        <i className="material-icons left">arrow_back</i>Back to dashboard
      </Link>
      <br />
      <center>
        <h5
          style={{
            display: "inline-block",
            marginLeft: "5rem",
            fontWeight: "bold",
          }}
        >
          Profile Details
        </h5>
        <Link
          to={`/view/client/${id}/edit`}
          className="waves-effect waves-light btn darken-3 hoverable"
          style={{ margin: "10px 5px", marginLeft: "15rem" }}
        >
          Edit
        </Link>
      </center>
      <ProfileAccordion client={clientData} />
      <br />
      <br />
      <br />
      <div>
        {isAdmin ? (
          <IconButton
            color="primary"
            aria-label="download"
            onClick={onDownloadData}
            disabled={!data || !data.length}
            style={{ margin: "10px" }}
          >
            <FileDownloadRounded />
          </IconButton>
        ) : (
          ""
        )}
        <Button
          sx={{ color: "black", background: "lightblue", float: "right" }}
          onClick={handleClickOpen}
        >
          Add Contact
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Add new contact
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                email: "",
                name: "",
                designation: "",
                dob: "",
                anniversary: "",
                phone: "",
                address: "",
                city: "",
                state: "",
                pin: "",
                maritalStatus: "",
              }}
              onSubmit={onSaveStaff}
            >
              {(props) => {
                const {
                  values,
                  dirty,
                  setFieldValue,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <br />
                    <input
                      value={values.name}
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Name"
                      type="text"
                      required
                    />
                    {/* {errors.name && touched.name && (
                        <div className="input-feedback" style={{color: "red"}}>{errors.name}</div>
                      )} */}
                    <input
                      value={values.email}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Email"
                      type="email"
                      // required
                    />
                    <input
                      value={values.designation}
                      name="designation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Designation"
                      type="text"
                      required
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="DOB"
                        value={values.dob}
                        variant="standard"
                        fullWidth
                        onChange={(newValue) => {
                          setFieldValue("dob", newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="dd/MM/yyyy"
                      />
                    </LocalizationProvider>
                    {/* <label>DOB</label>
                    <input
                      value={values.dob}
                      name="dob"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="dob"
                      type="date"
                      required
                    /> */}
                    <input
                      value={values.phone}
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Phone"
                      type="text"
                      required
                    />
                    <Stack direction="row" gap={1} sx={{ width: "100%" }}>
                      <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          Marital Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={values.maritalStatus}
                          onChange={(e) =>
                            setFieldValue("maritalStatus", e.target.value)
                          }
                          label="Marital Status"
                          required
                        >
                          <MenuItem value="">
                            <em>Select Marital Status</em>
                          </MenuItem>
                          <MenuItem value="married">Married</MenuItem>
                          <MenuItem value="unmarried">Unmarried</MenuItem>
                        </Select>
                      </FormControl>
                      {values.maritalStatus === "married" ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Anniversary"
                            value={values.anniversary}
                            fullWidth
                            variant="standard"
                            onChange={(newValue) => {
                              setFieldValue("anniversary", newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat="dd/MM/yyyy"
                          />
                        </LocalizationProvider>
                      ) : (
                        ""
                      )}
                    </Stack>

                    {/* <label>Anniversary</label>
                    <input
                      value={values.anniversary}
                      name="anniversary"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Anniversary"
                      type="date"
                      required
                    /> */}
                    <input
                      value={values.address}
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Address"
                      type="text"
                      required
                    />
                    <input
                      value={values.city}
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="City"
                      type="text"
                      required
                    />
                    <input
                      value={values.state}
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="State"
                      type="text"
                      required
                    />
                    <input
                      value={values.pin}
                      name="pin"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Pin"
                      type="text"
                      required
                    />
                    <DialogActions>
                      <Button
                        type="button"
                        className="outline"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >
                        Reset
                      </Button>
                      <Button type="submit" disabled={isSubmitting}>
                        Submit
                      </Button>
                    </DialogActions>
                  </form>
                );
              }}
            </Formik>
          </DialogContent>
          {/* <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Disagree
            </Button>
            <Button onClick={handleClose} autoFocus>
              Agree
            </Button>
          </DialogActions> */}
        </Dialog>
      </div>
      <br />
      <br />
      <StaffAccordion client={data} id={id} clientData={clientData} />
    </>
  );
};
