import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import firebase from "../firebaseConfig";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import M from "materialize-css/dist/js/materialize.min.js";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { confirmAlert } from "react-confirm-alert";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import "./HomePage.css";

function HomePage() {
  const [events, setEvents] = useState([]);
  // const [title, setTitle] = useState("");
  // const [description, setDescription] = useState("");
  // const [startTime, setStartTime] = useState("");
  // const [endTime, setEndTime] = useState("");
  const titleRef = React.useRef();
  const descRef = React.useRef();
  const startRef = React.useRef();
  const endRef = React.useRef();
  const eventsCollectionRef = collection(db, "Events");
  const [eventId, setEventId] = useState(null);

  const isAdmin = localStorage.getItem("isAdmin") === "true" ? true : false;

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    const events = await getDocs(eventsCollectionRef);
    setEvents(events.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setEvents((state) => {
      return state;
    });
    // };
  };

  function renderSidebar() {
    return (
      <div className="demo-app-sidebar">
        <div className="demo-app-sidebar-section">
          {isAdmin ? <h4>Admin</h4> : <h4>Welcome</h4>}
          <h5>
            Go to <Link to="/dashboard">Dashboard</Link>
          </h5>
        </div>
        <div className="demo-app-sidebar-section">
          <h4>All Events ({events.length})</h4>
          <ul>{events.map(renderSidebarEvent)}</ul>
        </div>
      </div>
    );
  }

  //add new event
  const handleDateSelect = (selectInfo) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h5>Add event</h5>
            <input
              type="text"
              placeholder="Event name"
              ref={titleRef}
              // onChange={(e) => setTitle(e.target.value)}
            />
            <input type="text" placeholder="Event description" ref={descRef} />
            <input
              type="Date"
              placeholder="Event Date"
              disabled
              value={selectInfo.startStr}
            />
            <input type="time" placeholder="start time" ref={startRef} />
            <input type="time" placeholder="end time" ref={endRef} />
            <br />
            <br />
            <button
              className="waves-effect waves-light btn-small"
              onClick={() => {
                const newEvent = {
                  title: titleRef.current.value,
                  description: descRef.current.value,
                  start: selectInfo.startStr + "T" + startRef.current.value,
                  end: selectInfo.startStr + "T" + endRef.current.value,
                };
                firebase
                  .firestore()
                  .collection("Events")
                  .add(newEvent)
                  .then((res) => {
                    // let calendarApi = selectInfo.view.calendar;

                    // calendarApi.unselect(); // clear date selection
                    // calendarApi.addEvent(newEvent);
                    setEventId(res.id);
                    M.toast({
                      html: "event added succesfully",
                      classes: "green darken-1 rounded",
                    });
                    onClose();
                    setEvents((state) => [...state, newEvent]);
                  })
                  .catch(() => {
                    M.toast({
                      html: "Something went wrong. Please try again.",
                      classes: "red darken-1 rounded",
                    });
                  });
              }}
            >
              Add Event
            </button>
            <button
              className="waves-effect waves-light btn-small"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        );
      },
    });
  };

  const handleEventClick = (clickInfo) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h5>Event Details</h5>
            <input
              type="text"
              disabled
              placeholder="Event name"
              value={clickInfo.event.title}
            />
            <input
              type="text"
              disabled
              placeholder="Event description"
              value={clickInfo.event.extendedProps.description}
            />
            <input
              type="text"
              disabled
              placeholder="Event time"
              value={clickInfo.event.startStr}
            />
            <input
              type="text"
              disabled
              placeholder="Event time"
              value={clickInfo.event.endStr}
            />
            <br />
            <br />
            <button
              className="waves-effect waves-light btn-small"
              onClick={() => {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="custom-ui">
                        <h5>Are you sure?</h5>
                        <p>You want to delete this event?</p>
                        <br />
                        <button
                          class="waves-effect waves-light btn-small"
                          onClick={() => {
                            const docRef = doc(
                              eventsCollectionRef,
                              clickInfo.event.id ? clickInfo.event.id : eventId
                            );
                            deleteDoc(docRef)
                              .then(() => {
                                onClose();
                                getEvents();
                                M.toast({
                                  html: "event deleted succesfully",
                                  classes: "green darken-1 rounded",
                                });
                              })
                              .catch((err) => {
                                M.toast({
                                  html: "Something went wrong. Please try again.",
                                  classes: "red darken-1 rounded",
                                });
                              });
                          }}
                        >
                          Yes
                        </button>
                        <button
                          class="waves-effect waves-light btn-small"
                          onClick={onClose}
                        >
                          No
                        </button>
                      </div>
                    );
                  },
                });
              }}
            >
              Delete
            </button>
            <button
              className="waves-effect waves-light btn-small"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        );
      },
    });
  };

  // const handleEvents = (events) => {
  //   setEvents(events);
  // };

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  function renderSidebarEvent(event) {
    return (
      <li className="list" key={event.id}>
        <b>
          {formatDate(event.start, {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </b>
        <i>{event.title}</i>
      </li>
    );
  }

  return (
    <div className="demo-app">
      {renderSidebar()}
      <div className="demo-app-main">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView="dayGridMonth"
          editable={false}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          events={events}
          //prevent selection of past dates
          // validRange={{
          //   start: new Date(),
          // }}
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
        />
      </div>
    </div>
  );
}

export default HomePage;
