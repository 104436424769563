import * as XLSX from "xlsx";

export const downloadJSONtoExcel = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export const capitalizeFirstLetter = (str = "") => {
  if (!str) return "";
  return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
};

export const isSameDayAndMonth = (m1, m2) =>
  m1.date() === m2.date() && m1.month() === m2.month();
