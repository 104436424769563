import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import M from "materialize-css/dist/js/materialize.min.js";
import "./Dashboard.css";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/material";
import EventsModal from "./common/eventsModal";
import { downloadJSONtoExcel, isSameDayAndMonth } from "../utils";
import { useAuth, useFirebaseData } from "../hooks";
import { collectionNames } from "../constants/firebase";
import { firebaseQueryOperators } from "../utils/queryBuilder";
import Loader from "./common/Loader";
import { deleteStoryItem } from "../services/deleteServices";
import moment from "moment";
import { format } from "date-fns";
import { getStoryItem } from "../services/getServices";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ViewClintPage = () => {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState({ item: [] });
  const [Filterclients, setFilterClients] = useState([]);
  const isAdmin = user?.isAdmin;

  const { data, isFetching } = useFirebaseData(collectionNames.CLIENTS, [
    {
      property: "user",
      operator: firebaseQueryOperators.NOT_EQUAL_TO,
      value: user?.email,
    },
  ]);

  const { data: eventsData, isFetching: isEventsFetching } = useFirebaseData(
    collectionNames.STAFF,
    user && user?.isAdmin
      ? []
      : [
          {
            property: "user",
            operator: firebaseQueryOperators.EQUAL_TO,
            value: user?.email,
          },
        ]
  );

  const events =
    eventsData && eventsData.length > 0
      ? eventsData.filter(
          ({ dob, anniversary }) =>
            (dob &&
              isSameDayAndMonth(moment(dob?.toDate()), moment(new Date()))) ||
            (anniversary &&
              isSameDayAndMonth(
                moment(anniversary?.toDate()),
                moment(new Date())
              ))
        )
      : [];

  useEffect(() => {
    let Data = [];
    data &&
      data.length > 0 &&
      data.map((item) => {
        let isPresent = false;
        let index = -1;
        for (let i = 0; i < Data.length; i++) {
          if (Data[i].user == item.user) {
            isPresent = true;
            index = i;
            break;
          }
        }
        if (isPresent) Data[index].item.push(item);
        else Data.push({ user: item.user, item: [item] });
        return item;
      });
    setFilterClients(Data);
  }, [data]);

  const onDelete = async (id, onClose) => {
    try {
      const res = await deleteStoryItem(collectionNames.CLIENTS, id);
      if (res) {
        onClose();
        M.toast({
          html: "client deleted succesfully",
          classes: "red darken-1 rounded",
        });
      } else {
        M.toast({
          html: "Something went wrong. Please try again.",
          classes: "red darken-1 rounded",
        });
      }
    } catch (error) {
      M.toast({
        html: "Something went wrong. Please try again.",
        classes: "red darken-1 rounded",
      });
    }
  };

  const handleDelete = async (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h5>Are you sure?</h5>
            <p>You want to delete this?</p>
            <br />
            <button
              class="waves-effect waves-light btn-small"
              onClick={() => onDelete(id, onClose)}
            >
              Yes
            </button>
            <button
              class="waves-effect waves-light btn-small"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
    });
  };

  const onDownload = () => {
    downloadJSONtoExcel(
      currentItem.item.map(({ schoolName, email, city }, index) => ({
        no: index + 1,
        schoolName,
        email,
        city,
      })),
      "User Data"
    );
  };

  const onDownloadData = async () => {
    const finalData = [];
    await Promise.all(
      currentItem.item.map(async ({ schoolName, id }, index) => {
        const schoolData = await getStoryItem(collectionNames.STAFF, [
          {
            property: "schoolId",
            operator: firebaseQueryOperators.EQUAL_TO,
            value: id,
          },
        ]);
        if (schoolData && schoolData.length > 0) {
          schoolData.forEach((item) => {
            finalData.push({
              name: item.name,
              user: item.user,
              email: item.email,
              designation: item.designation,
              phone: item.phone,
              schoolName,
              address: item?.address,
              city: item?.city,
              pin: item?.pin,
              state: item?.state,
              maritalStatus: item?.maritalStatus,
              anniversary: item?.anniversary
                ? format(item?.anniversary?.toDate(), "dd/MM/yyyy")
                : "",
              dob: item?.dob ? format(item?.dob?.toDate(), "dd/MM/yyyy") : "",
              createdAt: item?.createdAt
                ? format(item?.createdAt?.toDate(), "dd/MM/yyyy")
                : "",
            });
          });
        }
      })
    );
    await downloadJSONtoExcel(finalData, "All Related Contact Data");
  };

  return isFetching ? (
    <Loader />
  ) : (
    <div className="row">
      <div className="col s12 m12">
        <br />
        <Grid container spacing={4} sx={{ borderWidth: 2 }} alignItems="center">
          <Grid item md={4} sm={6} xs={6} lg={4} xl={4}>
            <div className="input-field">
              <input
                id="school"
                type="text"
                className="validate"
                style={{ maxWidth: 300 }}
                onChange={(e) => {
                  const array = [];
                  let Data = [];
                  data &&
                    data.length > 0 &&
                    data.map((item) => {
                      let isPresent = false;
                      let index = -1;
                      for (let i = 0; i < Data.length; i++) {
                        if (Data[i].user == item.user) {
                          isPresent = true;
                          index = i;
                          break;
                        }
                      }
                      if (isPresent) Data[index].item.push(item);
                      else Data.push({ user: item.user, item: [item] });
                      return item;
                    });
                  setFilterClients(Data);
                  if (e.target.value !== "") {
                    Data.map((item) => {
                      if (
                        item.user
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      ) {
                        array.push(item);
                      }
                      return item;
                    });
                    setFilterClients([...array]);
                  }
                }}
              />
              <label htmlFor="school">Search email</label>
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={6} lg={4} xl={4}></Grid>
          <Grid item md={4} sm={6} xs={6} lg={4} xl={4}>
            <div>
              <Button
                variant="contained"
                color="success"
                onClick={() => setIsEventModalOpen(true)}
              >
                Activity
              </Button>
            </div>
            {isEventModalOpen && (
              <EventsModal
                open={isEventModalOpen}
                handleClose={() => setIsEventModalOpen(false)}
                events={events}
                isLoading={isEventsFetching}
              />
            )}
            <div
              style={{
                display: "flex",
                flexDiraction: "row",
                justifyContent: "flex-end",
              }}
            >
              <Link
                to="/Dashboard"
                className="waves-effect waves-light btn blue darken-3 hoverable"
                style={{ margin: "10px 0px", alignSelf: "flex-end" }}
              >
                Go To Admin
              </Link>
            </div>
          </Grid>
        </Grid>
        <div className="row">
          {Filterclients.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>No . </StyledTableCell>
                    <StyledTableCell align="left">Email</StyledTableCell>
                    <StyledTableCell align="left">Total School</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Filterclients.map((row, index) => (
                    <StyledTableRow key={row.schoolName}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.user}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.item.length}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <div
                          style={{
                            display: "flex",
                            flexDiraction: "row",
                            alignItems: "center",
                          }}
                        >
                          <button
                            class="waves-effect waves-light btn darken-3 hoverable"
                            onClick={() => {
                              setCurrentItem(row);
                              setOpen(true);
                            }}
                          >
                            View Details
                          </button>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <h3>No Clients available</h3>
          )}
        </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>{currentItem?.user}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                onClick={onDownloadData}
                className="waves-effect waves-light btn green darken-3 hoverable"
                style={{ alignSelf: "flex-end" }}
                disabled={!currentItem.item || !currentItem.item?.length}
              >
                Download Related Contacts
              </Button>
              <Button
                variant="contained"
                onClick={onDownload}
                className="waves-effect waves-light btn green darken-3 hoverable"
                style={{ alignSelf: "flex-end" }}
                disabled={!currentItem.item || !currentItem.item?.length}
              >
                Download
              </Button>
            </Stack>

            {currentItem.item.length !== 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>No . </StyledTableCell>
                      <StyledTableCell align="left">
                        School Name
                      </StyledTableCell>
                      <StyledTableCell align="left">Email</StyledTableCell>
                      <StyledTableCell align="left">City</StyledTableCell>
                      <StyledTableCell align="left"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentItem.item.map((row, index) => (
                      <StyledTableRow key={row.schoolName}>
                        <StyledTableCell component="th" scope="row">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.schoolName}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.city}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <div
                            style={{
                              display: "flex",
                              flexDiraction: "row",
                              alignItems: "center",
                            }}
                          >
                            <Link
                              to={`/view/client/${row.id}`}
                              className="waves-effect waves-light btn darken-3 hoverable"
                            >
                              View Profile
                            </Link>
                            {isAdmin && (
                              <Link
                                to="#"
                                onClick={(e) => handleDelete(e, row.id)}
                                className="waves-effect waves-light btn darken-3 hoverable"
                                style={{
                                  backgroundColor: "red",
                                  marginLeft: "50px",
                                }}
                              >
                                Delete
                              </Link>
                            )}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <h3>No Clients available</h3>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewClintPage;
