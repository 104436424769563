import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebaseConfig";

// Delete Story Item - set deleteDocument flag to true
export const deleteStoryItem = (collectionName, docId) =>
  new Promise((resolve, reject) => {
    deleteDoc(doc(db, collectionName, docId))
      .then(() => 
        resolve(true))
      .catch((error) => reject(false));
  });
