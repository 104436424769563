import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebaseConfig";
import M from "materialize-css/dist/js/materialize.min.js";
import "./Navbar.css";
import LogoImage from "./YEP white.png";
import { IconButton } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import EventsModal from "./common/eventsModal";
import { useAuth, useFirebaseData } from "../hooks";
import { firebaseQueryOperators } from "../utils/queryBuilder";
import { collectionNames } from "../constants/firebase";
import { isSameDayAndMonth } from "../utils";
import moment from "moment/moment";

const NavBar = () => {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const { data, isFetching } = useFirebaseData(
    collectionNames.STAFF,
    ...(user?.isAdmin
      ? []
      : [
          {
            property: "user",
            operator: firebaseQueryOperators.EQUAL_TO,
            value: user?.email,
          },
        ])
  );
  const events =
    data && data.length > 0
      ? data.filter(
          ({ dob, anniversary }) =>
            (dob &&
              isSameDayAndMonth(
                moment(dob?.toDate()),
                moment(new Date()).add(1, "days")
              )) ||
            (anniversary &&
              isSameDayAndMonth(
                moment(anniversary?.toDate()),
                moment(new Date()).add(1, "days")
              ))
        )
      : [];

  return (
    <div className="navbar-fixed">
      <nav className="blue-grey darken-4">
        <div className="container nav-wrapper">
          <Link to="#">
            <span className="brand-logo">YEP CRM</span>
          </Link>
          {user !== null && (
            <Link to="/HomePage">
              <span className="left">
                <img
                  src={LogoImage}
                  style={{
                    width: 100,
                    height: 70,
                    marginBottom: 3,
                    marginRight: 30,
                  }}
                  alt=""
                />
                {/* <i className="material-icons home">home</i> */}
              </span>
            </Link>
          )}
          {user === null && (
            <Link to="#">
              <span className="left">
                <img
                  src={LogoImage}
                  style={{
                    width: 100,
                    height: 70,
                    marginBottom: 3,
                    marginRight: 30,
                  }}
                  alt=""
                />
                {/* <i className="material-icons home">home</i> */}
              </span>
            </Link>
          )}
          {user && (
            <IconButton
              aria-label="delete"
              className="right"
              sx={{ color: "#fff" }}
              onClick={() => setOpen(true)}
              size="large"
            >
              {events && events.length > 0 ? (
                <NotificationsActiveIcon />
              ) : (
                <NotificationsIcon />
              )}
            </IconButton>
          )}
          {open && (
            <EventsModal
              open={open}
              handleClose={() => setOpen(false)}
              events={events}
              isLoading={isFetching}
            />
          )}
          {user !== null ? (
            <ul className="right">
              <li>
                <button
                  className="btn white black-text waves-effect waves-teal"
                  onClick={() => {
                    M.toast({
                      html: `Logout successfully`,
                      classes: "green darken-1 rounded",
                    });
                    auth.signOut();
                  }}
                >
                  Logout
                </button>
              </li>
            </ul>
          ) : null}
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
