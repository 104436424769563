import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import { getAuth } from "firebase/auth";

// admin
const firebaseConfig = {
  apiKey: "AIzaSyDCz0juCJfgZ_KLMCqbfxptGhMOi7kRObc",
  authDomain: "yep-crm.firebaseapp.com",
  projectId: "yep-crm",
  storageBucket: "yep-crm.appspot.com",
  messagingSenderId: "1020042451047",
  appId: "1:1020042451047:web:d6fed27f8bd2b52b4e9cc2",
};

//my-one
// const firebaseConfig = {
//   apiKey: "AIzaSyC7RWcFTPwOjBr7ydZaLAuloN-uxYYDpMQ",
//   authDomain: "yepcrm-a0152.firebaseapp.com",
//   projectId: "yepcrm-a0152",
//   storageBucket: "yepcrm-a0152.appspot.com",
//   messagingSenderId: "443879109717",
//   appId: "1:443879109717:web:b41935b76796b0dbc0d0dc",
// };

//test:
// const firebaseConfig = {
//   apiKey: "AIzaSyBo3276Cg5IDCdzpobs0f1_EzbAEzxcsOU",
//   authDomain: "crmproject-e3e3b.firebaseapp.com",
//   projectId: "crmproject-e3e3b",
//   storageBucket: "crmproject-e3e3b.appspot.com",
//   messagingSenderId: "1086106355595",
//   appId: "1:1086106355595:web:b3ae58bf939e272bd1118e",
// };

// const app = initializeApp(firebaseConfig);
const app = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = getAuth(app)

export default firebase;
