import { collection, doc, setDoc } from "firebase/firestore";
import { db, auth } from "../firebaseConfig";

export const addStoryItem = (collectionName, data) =>
  new Promise((resolve, reject) => {
    const newStoryItemRef = doc(collection(db, collectionName));
    setDoc(newStoryItemRef, {
      ...data,
      id: newStoryItemRef?.id,
      createdAt: new Date(),
      createdBy: auth?.currentUser?.uid,
    })
      .then(() => resolve(newStoryItemRef?.id))
      .catch((error) => reject(false));
  });
