import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { db } from "../../firebaseConfig";
import M from "materialize-css/dist/js/materialize.min.js";
import { format } from "date-fns";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { Formik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { capitalizeFirstLetter } from "../../utils";
import { deleteStoryItem } from "../../services/deleteServices";
import { collectionNames } from "../../constants/firebase";
import { updateStoryItem } from "../../services/updateServices";

export function ProfileAccordion({ client }) {
  return (
    <center>
      <Paper elevation={5} sx={{ width: "100%" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3} sm={12} md={6}>
                <Typography variant="h6">
                  <Box sx={{ display: "inline", color: "blue" }}>School</Box>
                  <Box sx={{ display: "block" }}>
                    {client?.at(0)?.schoolName}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3} sm={12} md={6}>
                <Typography variant="h6">
                  <Box sx={{ display: "inline", color: "blue" }}>Contact</Box>
                  <Box sx={{ display: "block" }}>{client?.at(0)?.contact}</Box>
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3} sm={12} md={6}>
                <Typography variant="h6">
                  <Box sx={{ display: "inline", color: "blue" }}>Email</Box>
                  <Box sx={{ display: "block" }}>{client?.at(0)?.email}</Box>
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3} sm={12} md={6}>
                <Typography variant="h6">
                  <Box sx={{ display: "inline", color: "blue" }}>City</Box>
                  <Box sx={{ display: "block" }}>{client?.at(0)?.city}</Box>
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="h6">Address:</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="body1">
                  {client?.at(0)?.address}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="h6">District:</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="body1">
                  {client?.at(0)?.district}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="h6">State:</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="body1">{client?.at(0)?.state}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="h6">Pin:</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="body1">{client?.at(0)?.pin}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="h6">Type:</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="body1">{client?.at(0)?.type}</Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </center>
  );
}
export function StaffAccordion({ client, id }) {
  const [open, setOpen] = React.useState(false);
  const [temp, setTemp] = React.useState({});
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = (staff) => {
    setOpen(true);
    setTemp(staff);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDelete = async (id, onClose) => {
    try {
      const res = await deleteStoryItem(collectionNames.STAFF, id);
      if (res) {
        onClose();
        M.toast({
          html: "Contact deleted succesfully",
          classes: "green darken-1 rounded",
          displayLength: 2000,
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  const onUpdate = async (data) => {
    try {
      const res = await updateStoryItem(collectionNames.STAFF, {
        ...data,
        id: temp.id,
      });
      if (res) {
        handleClose();
        M.toast({
          html: "Contact Edited succesfully",
          classes: "green darken-1 rounded",
          displayLength: 2000,
        });
      } else {
      }
    } catch (error) {}
  };

  const handleDelete = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h5>Are you sure?</h5>
            <p>You want to delete this contact?</p>
            <br />
            <button
              class="waves-effect waves-light btn-small"
              onClick={() => onDelete(id, onClose)}
            >
              Yes
            </button>
            <button
              class="waves-effect waves-light btn-small"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
    });
  };

  return (
    <center>
      <div>
        <Dialog
          // fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Edit Contact</DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                email: temp.email || "",
                name: temp.name || "",
                designation: temp.designation || "",
                dob: temp.dob ? temp.dob?.toDate() : "",
                anniversary: temp.anniversary ? temp.anniversary?.toDate() : "",
                phone: temp.phone || "",
                address: temp.address || "",
                city: temp.city || "",
                state: temp.state || "",
                pin: temp.pin || "",
                maritalStatus: temp?.maritalStatus || "",
              }}
              enableReinitialize={true}
              onSubmit={onUpdate}
            >
              {(props) => {
                const {
                  values,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <br />
                    <input
                      value={values.name}
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Name"
                      type="text"
                      required
                    />
                    {/* {errors.name && touched.name && (
                        <div className="input-feedback" style={{color: "red"}}>{errors.name}</div>
                      )} */}
                    <input
                      value={values.email}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Email"
                      type="email"
                      required
                    />
                    <input
                      value={values.designation}
                      name="designation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Designation"
                      type="text"
                      required
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="DOB"
                        value={values.dob}
                        variant="standard"
                        fullWidth
                        onChange={(newValue) => {
                          setFieldValue("dob", newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="dd/MM/yyyy"
                      />
                    </LocalizationProvider>
                    <input
                      value={values.phone}
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Phone"
                      type="text"
                      required
                    />
                    <Stack direction="row" gap={1} sx={{ width: "100%" }}>
                      <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          Marital Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={values.maritalStatus}
                          onChange={(e) => {
                            if (e.target.value === "unmarried") {
                              setFieldValue("anniversary", "");
                            }
                            setFieldValue("maritalStatus", e.target.value);
                          }}
                          label="Marital Status"
                          required
                        >
                          <MenuItem value="">
                            <em>Select Marital Status</em>
                          </MenuItem>
                          <MenuItem value="married">Married</MenuItem>
                          <MenuItem value="unmarried">Unmarried</MenuItem>
                        </Select>
                      </FormControl>
                      {values.maritalStatus === "married" ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Anniversary"
                            value={values.anniversary}
                            fullWidth
                            variant="standard"
                            onChange={(newValue) => {
                              setFieldValue("anniversary", newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat="dd/MM/yyyy"
                          />
                        </LocalizationProvider>
                      ) : (
                        ""
                      )}
                    </Stack>
                    <input
                      value={values.address}
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Address"
                      type="text"
                      required
                    />
                    <input
                      value={values.city}
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="City"
                      type="text"
                      required
                    />
                    <input
                      value={values.state}
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="State"
                      type="text"
                      required
                    />
                    <input
                      value={values.pin}
                      name="pin"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Pin"
                      type="text"
                      required
                    />
                    <DialogActions>
                      <Button
                        type="button"
                        className="outline"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >
                        Reset
                      </Button>
                      <Button type="submit" disabled={isSubmitting}>
                        Submit
                      </Button>
                    </DialogActions>
                  </form>
                );
              }}
            </Formik>
          </DialogContent>
        </Dialog>
      </div>

      <Paper elevation={5} sx={{ width: "100%" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">Related Contacts</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {client &&
              client.length > 0 &&
              client.map((item, index) => (
                <Paper elevation={5} sx={{ width: "100%" }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6">{item.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Button
                            sx={{
                              color: "black",
                              background: "lightblue",
                            }}
                            onClick={() => handleDelete(item.id)}
                          >
                            Delete
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Button
                            sx={{
                              color: "black",
                              background: "lightblue",
                            }}
                            onClick={() => handleClickOpen(item)}
                          >
                            Edit
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="h6">Email:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="body1">{item.email}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="h6">DOB:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="body1">
                            {item.dob
                              ? format(item.dob?.toDate(), "dd/MM/yyyy")
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="h6">Anniversary</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="body1">
                            {item.anniversary
                              ? format(item.anniversary?.toDate(), "dd/MM/yyyy")
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="h6">Marital Status</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="body1">
                            {item?.maritalStatus
                              ? capitalizeFirstLetter(item?.maritalStatus)
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="h6">Designation:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="body1">
                            {item.designation}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="h6">Phone:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="body1">{item.phone}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="h6">Address:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="body1">
                            {item.address}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="h6">City:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="body1">{item.city}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="h6">State:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="body1">{item.state}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="h6">Pin:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="body1">{item.pin}</Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Paper>
              ))}
          </AccordionDetails>
        </Accordion>
      </Paper>
    </center>
  );
}
