import { doc, increment, updateDoc } from "firebase/firestore";
import { db, auth } from "../firebaseConfig";

export const updateStoryItem = (collectionName, data) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, collectionName, data?.id), {
      ...data,
      updatedBy: auth?.currentUser?.uid,
      updateCount: increment(1),
      updatedAt: new Date(),
    })
      .then(() => resolve(data?.id))
      .catch(() => reject(false));
  });
